/* global instantsearch */
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { cleanseValue } from './utils/JsonCleanser';
import { convertJsonToHtml } from './utils/ConvertJsonToHtml';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    // apiKey: 'q2PYbaONST',
    // apiKey: 'DYYbzSYjwk5MFE9P8uHOCTPkG2IolFiI',
    apiKey: '0febb6ff-4691-4ed1-a5ce-28e9ae2ae452',
    nodes: [
      {
        host: 'api-varadhi.cubebase.ai',
        protocol: 'https',
        // host: '164.52.213.233',
        // port: '8108',
        // protocol: 'http',
      },
    ],
    connection_timeout_seconds: 60000000000,
  },

  additionalSearchParameters: {
    queryBy: 'observations,conclusion',
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const renderStats = (renderOptions) => {
  const { nbHits, processingTimeMS } = renderOptions;

  document.querySelector('#search-stats').innerHTML = `
      <strong>${nbHits}</strong> results found in <strong>${processingTimeMS}ms</strong>
  `;
};

const renderStudyIUIDText = (study_iuid) => {
  document.getElementById('study-iuid').textContent = 'loading...';
  fetch('https://api.5cnetwork.com/dicom/storage-path/' + study_iuid)
    .then(async (res) => {
      const pathObj = await res.json();
      document.getElementById('study-iuid').textContent =
        pathObj['path'] || 'No Path Founded';
    })
    .catch((err) => {
      console.log(err);
      document.getElementById('study-iuid').textContent = 'No Path';
    });
};

const customStatsWidget = instantsearch.connectors.connectStats(renderStats);

const search = instantsearch({
  searchClient,
  indexName: 'report_contents',
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  customStatsWidget(),
  instantsearch.widgets.refinementList({
    container: '#refinement-list',
    attribute: 'report_header',
    searchablePlaceholder: 'Report Header ...',
    limit: 8,
    searchable: true,
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list-1',
    attribute: 'report_mod_study',
    searchablePlaceholder: 'Modstudy ...',
    limit: 8,
    searchable: true,
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list-2',
    attribute: 'rad_name',
    searchablePlaceholder: 'Radiologist ...',
    limit: 8,
    searchable: true,
  }),
  instantsearch.widgets.sortBy({
    container: '#sortBy',
    items: [
      {
        label: 'Created At (asc)',
        value: 'report_contents/sort/created_at:asc',
      },
      {
        label: 'Created At (desc)',
        value: 'report_contents/sort/created_at:desc',
      },
    ],
  }),
  instantsearch.widgets.toggleRefinement({
    container: '#rework-toggle',
    attribute: 'is_rework',
    templates: {
      labelText() {
        return `&nbsp;&nbsp;Rework Case`;
      },
    },
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 12,
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item(item) {
        const observationsKeywords =
          item._highlightResult && item._highlightResult.observations
            ? item._highlightResult.observations.matchedWords
            : [];
        const keywordsString = observationsKeywords.join(' ');

        return `
        <div>
            <div class="hit-name"></div>
            <div>
                <div style="float: left; font-size: small; font-style: italic;">
                    <a href="https://admin.5cnetwork.com/cases/${
                      item.study_id
                    }?tab=Reports" target="_blank">${item.study_id}</a>
                </div>
                <div style=" float: right; font-size: small; font-style: italic;">
                    ${new Date(item.created_at).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                </div>
            </div>
            <div>
                <span style="font-size: 14px; font-weight: normal;" 
                    class="report-id" style="cursor:pointer;"
                    data-study-id="${item.study_id}"
                    data-report-header="${item.report_header}"
                    data-impression="${item.conclusion}"
                    data-study-iuid="${item.study_iuid}"
                    data-report-content="${encodeURIComponent(
                      item.report_content
                    )}"
                    data-radName="${item.rad_name}"
                    data-observation="${item.observations}"> 
                    <br>
                    ${
                      observationsKeywords.length > 0
                        ? item.observations.includes(keywordsString)
                          ? '...' +
                            item.observations
                              .split(keywordsString)[0]
                              .slice(-40) +
                            '<span style="background-color: #ffff00;">' +
                            keywordsString +
                            '</span>' +
                            item.observations
                              .split(keywordsString)[1]
                              .slice(0, 40 - keywordsString.length) +
                            '...'
                          : item.observations.slice(0, 80) + '...'
                        : item.observations.slice(0, 80) + '...'
                    }
                    <br>
                    ${
                      item._highlightResult &&
                      item._highlightResult.conclusion &&
                      keywordsString.length > 0
                        ? item.conclusion.includes(keywordsString)
                          ? ' ...' +
                            item.conclusion
                              .split(keywordsString)[0]
                              .slice(-30) +
                            '<span style="background-color: #ffff00;">' +
                            keywordsString +
                            '</span>' +
                            item.conclusion
                              .split(keywordsString)[1]
                              .slice(0, 30 - keywordsString.length) +
                            '...'
                          : item.conclusion.slice(0, 60) + '...'
                        : item.conclusion.slice(0, 60) + '...'
                    }</span>
            </div>
        </div>
      `;
      },
    },
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();

// function formatText(text) {
//   const parts = text.split(/(:-|:|(?<!\d)\.(?!\d))/);
//   let formattedText = parts
//     .map((part, index) => {
//       if ([':-', ':', '.'].includes(part)) {
//         return part === ':' ? ':-' : part;
//       }
//       return `${index > 0 ? '<br>' : ''}${part.trim()}`;
//     })
//     .join('');

//   formattedText = formattedText
//     .split('<br>')
//     .map((segment, index) => {
//       if (segment.length > 2 && ![':-', ':', '-', ',', ' '].includes(segment)) {
//         return segment;
//       }
//     })
//     .join('<br>');

//   return formattedText;
// }
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    if (event.target.matches('.report-id')) {
      // const reportHeader = event.target.getAttribute('data-report-header');
      const studyId = event.target.getAttribute('data-study-id');
      // const impression = event.target.getAttribute('data-impression');
      // const observation = event.target.getAttribute('data-observation');

      const report_content = event.target.getAttribute('data-report-content');
      convertJsonToHtml({
        children: cleanseValue(JSON.parse(decodeURIComponent(report_content))),
      }).then(
        (res) => (document.getElementById('modalReportContent').innerHTML = res)
      );
      // console.log({ report_content: decodeURIComponent(report_content) });
      const radName = event.target.getAttribute('data-radName');

      // document.getElementById('modalReportContent').innerHTML = report_content;
      // document.getElementById('modalReportHeader').textContent = reportHeader;
      document.getElementById('radName').textContent = '- ' + radName;
      renderStudyIUIDText(event.target.getAttribute('data-study-iuid'));
      document.getElementById(
        'studyLink'
      ).innerHTML = `<a class="studyIdText" href="https://admin.5cnetwork.com/cases/${studyId}?tab=Reports" target="_blank">${studyId}</a>`;
      // impression = impression
      //   .split('.')
      //   .filter((sentence) => sentence.trim().split(' ').length > 1)
      //   .join('.<br>');
      // document.getElementById('modalImpression').innerHTML = impression
      //   ? impression
      //   : '---';
      document.getElementById('observationModal').style.display = 'block';
    }

    if (event.target.matches('.close')) {
      document.getElementById('observationModal').style.display = 'none';
    }
  });

  window.onclick = function (event) {
    if (event.target == document.getElementById('observationModal')) {
      document.getElementById('observationModal').style.display = 'none';
    }
  };
});
